import React from "react";
import ImageSit from "../images/2023/12/sit.png";

function Temporaires(props) {
  return (
    <div>
      <h1>Les Ludothèques temporaires</h1>

      <div class="entry-content">
        <p>
          Vous ne pouvez pas venir à la ludothèque alors nous venons à vous une
          fois par mois !
        </p>

        <p>
          Un temp de jeu sur place gratuit et un prêt de jeu pour continuer à
          jouer à la maison !
        </p>

        <ul>
          <li>
            Le premier mardi de chaque mois à <strong>Yssingeaux</strong>, de
            16h30 à 18h au Teen&#8217;s Club, place Maréchal Foch.
          </li>
        </ul>

        <ul>
          <li>
            Le deuxième jeudi de chaque mois à <strong>Lantriac</strong>, de
            16h30 à 18h à la bibliothèque.
          </li>
        </ul>

        <ul>
          <li>
            Le deuxième samedi de chaque de 10h à 11h30 à{" "}
            <strong>St Julien Chapteuil</strong>, à l&#8217;Echappée.
          </li>
        </ul>

        <p></p>

        <p></p>

        <p></p>

        <figure class="wp-block-image size-large">
          <a href={ImageSit}>
            <img
              fetchpriority="high"
              decoding="async"
              width="1024"
              height="509"
              src={ImageSit}
              alt=""
              class="wp-image-2177"
              sizes="(max-width: 1024px) 100vw, 1024px"
            />
          </a>
        </figure>
      </div>
    </div>
  );
}

export default Temporaires;
